@import '../../hooks/themes/color-pallete.scss';
@import '../../index.scss';

.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include theme-aware('color', 'color-text');

  &__image {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: lightcoral;
    margin-bottom: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__name {
    font-size: 2.1rem;
    font-weight: 600;
  }

  &__skills {
    font-size: 0.8rem;
  }

  &__contact {
    background-color: $primary-color;
    color: #fff;
    font-size: .8rem;
    padding: .5rem .99rem;
    margin: 1rem 0 2rem 0;
    border-radius: 22px;
    transition: all .3s ease;

    &:hover {
      cursor: pointer;
      transform: translateY(-3px);
      box-shadow: 1px 2px 5px rgba($color: #000000, $alpha: .4);
      color: #ebebeb;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > a svg {
      border: 1px solid;
      @include theme-aware('color', 'color-text');
      @include theme-aware('border-color', 'color-text');
      border-radius: 50px;
      margin-top: 10px;
      padding: .3rem .1rem;
      transition: all .3s ease;

      &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: 1px 2px 5px rgba($color: #000000, $alpha: .4);
      }
    }
  }
}