$themes: (
  light: (
    color-background: #fff,
    color-text: #202020,
    color-link: rgba(0,0,0, .65),
    color-link-hover: rgba(0,0,0, 1),
    border-header: #ccc,
    span-date: #5e5d5d,
    title-text: #363636,
    primary-color: #AC0D0D,
    secondary-color: #F48B29,
    tertiary-color: #F0C929
  ),
  dark: (
    color-background: #313131,
    color-text: #e0e0e0,
    color-link: rgba(255, 255, 255, .7),
    color-link-hover: rgba(255, 255, 255, 1),
    border-header: #828282,
    span-date: #cecece,
    title-text: #f8f8f8,
    primary-color: #AC0D0D,
    secondary-color: #cc9765,
    tertiary-color: #F0C929
  )
);

@debug map-get(map-get($map: $themes, $key: 'light'), 'color-background');

@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
      .theme-#{$theme-name} & {
          #{$key}: map-get(map-get($themes, $theme-name), $color)
      }
  }
};