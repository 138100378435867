// @import url('./hooks/themes/light.scss');
@import './hooks/themes/color-pallete.scss';
@import './index.scss';

.app {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: center;
  position: relative;
  @include theme-aware('background-color', 'color-background');
}

.homepage {
  grid-column: 2 / -1;
  width: 100%;
  max-width: 1000px;
  height:  auto;
}

.button-menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  border: none;
  @include theme-aware('background-color', 'color-text');
  height: 3px;
  width: 2rem;
  margin-top: 2.5rem;
  margin-left: 2rem;
  border-radius: 100px;
  transition: all .6s ease;

  &::before,
  &::after {
    @include theme-aware('background-color', 'color-text');
    height: 3px;
    width: 2rem;
    border-radius: 100px;
    content: '';
    display: block;
  }

  &::before { transform: translateY(-.7rem); }
  &::after { transform: translateY(.5rem); }

  @media only screen and (max-width: $sc-mobile-l) {
    // display: none;
  }
  @media only screen and (min-width: $sc-mobile-l) {
    display: none;
  } 
}


