@import '../../hooks/themes/color-pallete.scss';
@import '../../index.scss';

.header {
  height: 100vh;
  width: 68px;
  position: sticky;
  top: 0;
  left: 0;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  @include theme-aware('color', 'color-link');
  border-right: 1px solid;
  @include theme-aware('border-color', 'border-header');
  transition: all .6s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (max-width: $sc-mobile-l) {
    position: fixed;
    transform: translateX(-250px);
    transition: all .6s ease;
  } 

  &:hover {
    cursor: pointer;
  }

  &__list {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &:last-child {
      margin-bottom: .4rem;
    }

    &:not(:last-child) li:hover {
        background-color: rgba($color: #000000, $alpha: .1);
    }
  }

  &__item {
    width: calc(100% - 1.1rem);
    display: flex;
    justify-content: flex-start;
    padding: .5rem 0;
    padding-left: 1.1rem;
    margin: .25rem 0;
    font-weight: 300;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include theme-aware('color', 'color-link');

    &:hover {
      @include theme-aware('color', 'secondary-color');
    }

    & > svg {
      min-height: 25px;
      min-width: 25px;
      font-size: 1.6rem;
      padding-right: .7rem ;
    }

    &--text {
      width: 0px;
      overflow: hidden;
      font-size: 1.15rem;
      transition: width .4s ease-in-out;
      margin-left: 1.2rem;
    }
  }
}

.link-active {
  @include theme-aware('color', 'secondary-color');
}

.open {
  width: 248px;
}

.back-mobile {
  @include theme-aware('background-color', 'color-background');
}

.logo {
  height: 2.1rem;
  width: auto;
  margin-top: 1rem;
  margin-left: 1.1rem;
}

.text-open {
  width: 100%;
}

.buttons-mode {
  position: relative;
}

.button-color-mode {
  perspective: 150rem;

  &__side {
    display: flex;
    align-items: center;
    @include theme-aware('color', 'color-link');
    background-color: transparent;
    border: none;
    // box-shadow: 1px 2px 7px 1px rgba($color: #000000, $alpha: .5);
    padding: .1rem .5rem;
    position: absolute;
    top: -30px;
    left: 9px;
    backface-visibility: hidden;
    overflow: hidden;
    transition: all .8s ease;

    & > svg {
      min-height: 25px;
      min-width: 25px;
      font-size: 1.6rem;
      padding-right: .7rem ;
    }

    &--back {
      transform: rotateX(180deg);
    }
  }

  &__side:hover {
    cursor: pointer;
  }

  // &:hover &__side--front {
  //   transform: rotateX(-180deg);
  // }

  // &:hover &__side--back {
  //   transform: rotateX(0deg);
  // }
}

.mode-lang {
  &__select {
    margin-left: 1.2rem;
    border-radius: 5px;
    width: 90%;
    max-width: 8rem;
    padding: 0 .4rem;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }
}

.button-header-arrow,
.mode-lang__button {
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  @include theme-aware('color', 'color-link');
  transition: transform .6s ease;

  &:hover {
    cursor: pointer;
  }
}
