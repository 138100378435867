$text-color: #000;

$primary-color: #AC0D0D;
$secondary-color: #F48B29;
$tertiary-color: #F0C929;
$fourth-color: #FBE6C2;

$sc-medium: 850px;
$sc-tablet: 725px;
$sc-mobile-l: 550px;
$sc-mobile-m: 445px;
$sc-mobile-s: 360px;



*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  * {
    margin: 0;
    padding: 0;
  }
}

a {
  text-decoration: none;
}
